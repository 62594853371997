import { AgreeUploadResultModal, UploadManualResponse } from '@admin/models';
import { Injectable } from '@angular/core';
import { ENDPOINT } from '@shared/config/endpoint';
import { BaseService } from '@shared/service/base.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AudioService {
  private _agreeUploadResultModal = new BehaviorSubject<AgreeUploadResultModal>({
    isOpen: false,
    data: {
      numOfSuccess: 0,
      numOfFail: 0
    }
  });

  agreeUploadResultModal$ = this._agreeUploadResultModal.asObservable();

  constructor(private baseService: BaseService) {}

  setAgreeUploadResultModal(value: AgreeUploadResultModal) {
    this._agreeUploadResultModal.next(value);
  }

  getListAudio(params) {
    const paramsObj = this.baseService.paramStringArray(params);
    return this.baseService.getData(`${ENDPOINT.audio.getListData}?${paramsObj.toString()}`);
  }

  getAudioHistory(audioId: string) {
    const requestUrl = `${ENDPOINT.audio.getHistory}/${audioId}/history`;
    return this.baseService.getData(requestUrl);
  }

  getMetadataConfig() {
    const requestUrl = `${ENDPOINT.audio.getMetadataConfig}`;
    return this.baseService.getData(requestUrl);
  }

  validateUpload(formData: FormData) {
    return this.baseService.postUpload(ENDPOINT.audio.validateUpload, formData);
  }

  manualUploadAudio(formData: FormData): Observable<UploadManualResponse> {
    return this.baseService.postUpload(ENDPOINT.audio.uploadManual, formData);
  }

  getAudioError(params: any) {
    const queryString = this.baseService.paramStringArray(params);
    return this.baseService.getData(`${ENDPOINT.audio.audioError}?${queryString.toString()}`);
  }

  reEvaluateAudio(params: []) {
    return this.baseService.putData(ENDPOINT.audio.reEvaluateAudio, { audioStatusIds: params });
  }
}
