import { Injectable } from '@angular/core';
import { ENDPOINT } from '@shared/config/endpoint';
import { BaseService } from '@shared/service/base.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private baseService: BaseService) {}

  packageValidate$: Subject<boolean> = new Subject<boolean>();
  isInValidOrgPricePackage = false;

  validateOrgPricePackage() {
    this.getOrgPricePackageRemain().subscribe({
      next: (res: any) => {
        const {
          remainDuration,
          isRunExtra
        } = res.data;
        if (!remainDuration) {
          this.isInValidOrgPricePackage = true;
          this.packageValidate$.next(false);
          return;
        }

        if (remainDuration <= 0 && !isRunExtra) {
          this.isInValidOrgPricePackage = true;
          this.packageValidate$.next(false);
          return;
        }
      },
      error: () => {
        this.isInValidOrgPricePackage = true;
        this.packageValidate$.next(false);
      }
    });
  }

  getEmbedReport() {
    const url = `${ENDPOINT.report.embedReport}`;
    return this.baseService.getData(url);
  }

  getEmbedList() {
    const url = `${ENDPOINT.report.embedList}`;
    return this.baseService.getData(url);
  }

  getInternalList() {
    const url = `${ENDPOINT.report.internalList}`;
    return this.baseService.getData(url);
  }

  getOrgPricePackageRemain() {
    const url = `${ENDPOINT.report.orgPricePackageRemainDuration}`;
    return this.baseService.getData(url);
  }
}
