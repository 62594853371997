// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nz-select.select-over {
  width: 72px;
}
@media (max-width: 1660px) {
  nz-select.select-over {
    left: -8px;
  }
}
@media (max-width: 1456px) {
  nz-select.select-over {
    left: -16px;
  }
}
@media (max-width: 1304px) {
  nz-select.select-over {
    width: 60px;
  }
}

.kpi-est {
  width: 100%;
}

::ng-deep .kpi-est .failed {
  color: #ff0000;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImtwaS1lc3RpbWF0aW9uLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUlFO0VBQ0UsV0FBQTtBQUhKO0FBSUk7RUFGRjtJQUdJLFVBQUE7RUFESjtBQUNGO0FBRUk7RUFMRjtJQU1JLFdBQUE7RUFDSjtBQUNGO0FBQUk7RUFSRjtJQVNJLFdBQUE7RUFHSjtBQUNGOztBQUNBO0VBQ0UsV0FBQTtBQUVGOztBQUdJO0VBQ0UsY0FBQTtBQUFOIiwiZmlsZSI6ImtwaS1lc3RpbWF0aW9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAndmFyaWFibGUnO1xuQGltcG9ydCAnbWl4aW5zJztcblxubnotc2VsZWN0IHtcbiAgJi5zZWxlY3Qtb3ZlciB7XG4gICAgd2lkdGg6IDcycHg7XG4gICAgQG1lZGlhIChtYXgtd2lkdGg6IDE2NjBweCkge1xuICAgICAgbGVmdDogLThweDtcbiAgICB9XG4gICAgQG1lZGlhIChtYXgtd2lkdGg6IDE0NTZweCkge1xuICAgICAgbGVmdDogLTE2cHg7XG4gICAgfVxuICAgIEBtZWRpYSAobWF4LXdpZHRoOiAxMzA0cHgpIHtcbiAgICAgIHdpZHRoOiA2MHB4O1xuICAgIH1cbiAgfVxufVxuXG4ua3BpLWVzdCB7XG4gIHdpZHRoOiAxMDAlO1xufVxuXG46Om5nLWRlZXAge1xuICAua3BpLWVzdCB7XG4gICAgLmZhaWxlZCB7XG4gICAgICBjb2xvcjogbWFwLWdldCgkY29sb3IsIGVycm9yKTtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/feature/admin/components/evaluation/components/kpi-estimation/kpi-estimation.component.scss"],"names":[],"mappings":"AAIE;EACE,WAAA;AAHJ;AAII;EAFF;IAGI,UAAA;EADJ;AACF;AAEI;EALF;IAMI,WAAA;EACJ;AACF;AAAI;EARF;IASI,WAAA;EAGJ;AACF;;AACA;EACE,WAAA;AAEF;;AAGI;EACE,cAAA;AAAN;AACA,o+BAAo+B","sourcesContent":["@import 'variable';\n@import 'mixins';\n\nnz-select {\n  &.select-over {\n    width: 72px;\n    @media (max-width: 1660px) {\n      left: -8px;\n    }\n    @media (max-width: 1456px) {\n      left: -16px;\n    }\n    @media (max-width: 1304px) {\n      width: 60px;\n    }\n  }\n}\n\n.kpi-est {\n  width: 100%;\n}\n\n::ng-deep {\n  .kpi-est {\n    .failed {\n      color: map-get($color, error);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
