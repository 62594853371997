import { IInternalReportSetting } from '@admin/models/side-bar/side-bar.model';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { ReportConfigName } from '@shared/constant';

export const INTERNAL_REPORTS: IInternalReportSetting[] = [
  {
    field: ReportConfigName.RECONCILIATION,
    level: 1,
    title: 'report.reconciliation.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/reconciliation',
    rights: []
  },
  {
    field: ReportConfigName.CALL_ANALYSIS,
    level: 1,
    title: 'report.call_analysis.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/call-analysis',
    rights: [RIGHTS_CODE.RPT_001]
  },
  {
    field: ReportConfigName.VIOLATION_CALLS_SUMMARY,
    level: 1,
    title: 'report.violation.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/violation-summary',
    rights: [RIGHTS_CODE.RPT_003]
  },
  {
    field: ReportConfigName.VOLUME,
    level: 1,
    title: 'report.volumn.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/volume',
    rights: [RIGHTS_CODE.RPT_005]
  },
  {
    field: ReportConfigName.VIOLATION_BY_AGENT,
    level: 1,
    title: 'report.violation_by_agent.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/violation-by-agent',
    rights: [RIGHTS_CODE.RPT_007]
  },
  {
    field: ReportConfigName.ATTITUDE_KW_BUCKET,
    level: 1,
    title: 'common.filter.attitude_kw.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/attitude-kw-bucket',
    rights: [RIGHTS_CODE.RPT_009]
  },
  {
    field: ReportConfigName.ASSIGNED_CALLS,
    level: 1,
    title: 'report.assigned_call.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/assigned-call',
    rights: [RIGHTS_CODE.RPT_011]
  },
  {
    field: ReportConfigName.ASSIGNED_CALLS_BY_QC,
    level: 1,
    title: 'report.assigned_call_by_qc.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/assigned-call-by-qc',
    rights: [RIGHTS_CODE.RPT_013]
  }
];
