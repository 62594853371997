import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ReportService } from '@admin/services/report/report.service';

@Injectable({
  providedIn: 'root'
})
export class PackageValidationGuard implements CanActivate {
  constructor(
    private reportService: ReportService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (!this.reportService.isAllowedUpload()) {
      this.router.navigate(['/admin/audio-management']);
      return false;
    }

    return true;
  }
}