import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { PermissionsService } from '@shared/service/permission.service';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AudioService } from '@admin/services/audio-manager/audio-manager.service';
import { AudiosItems } from '@admin/models/audio-manager/audio-manager';
import { NgxSpinnerService } from 'ngx-spinner';
import { AUDIO_STATUS } from '@admin/constant/audio/audio-status.constant';
import { LayoutService } from '@core/layout/services/layout.service';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReportService } from '@admin/services/report/report.service';

@Component({
  selector: 'app-audio-total',
  templateUrl: './audio-total.component.html',
  styleUrls: ['./audio-total.component.scss'],
  providers: [LayoutService]
})
export class AudioTotalComponent implements OnInit, OnDestroy {
  audioService = inject(AudioService);

  RIGHTS_CODE = RIGHTS_CODE;
  audioStatus = AUDIO_STATUS;
  isShowFilter = false;
  headersList = [
    { title: 'common.table.file_name', width: '180px' },
    { title: 'common.table.call_id', width: '180px' },
    { title: 'common.table.agent_id', width: '180px' },
    { title: 'common.table.upload_date', width: '180px' },
    { title: 'common.table.contact_date', width: '180px' },
    { title: 'common.table.updated_date', width: '180px' },
    { title: 'common.table.number_of_successful_evaluation', width: '140px' },
    { title: 'common.table.status', width: '120px' },
    { title: 'common.table.engineName', width: '180px' },
    { title: 'common.table.history.title', width: '80px' }
  ];

  dataTable: AudiosItems[];
  totalPages: number;
  pageIndex = 0;
  pageSize = 10;

  isShowUpload = false;
  isShowHistory = false;
  audioId = '';
  filterParams = {};
  dataOrganizations: string[];
  private subscription: Subscription;

  agreeUploadResultModal$ = this.audioService.agreeUploadResultModal$;

  constructor(
    private spinner: NgxSpinnerService,
    private organizationsGlobalService: OrganizationsGlobalService,
    private router: Router,
    public permissionsService: PermissionsService,
    public reportService: ReportService
  ) {}

  ngOnInit(): void {
    this.subscription = this.organizationsGlobalService.valueOrganizations$.subscribe({
      next: result => {
        if (result) {
          this.dataOrganizations = result.org;
          this.getListData(1);
        }
      }
    });
  }

  getListData(pageIndex: number) {
    const params = this.setParams(pageIndex);
    this.pageIndex = pageIndex;
    this.spinner.show();
    this.audioService.getListAudio(params).subscribe({
      next: (res: any) => {
        this.totalPages = res.data.total;
        this.dataTable = res.data.data.map((item: any) => {
          return new AudiosItems(item);
        });
        this.spinner.hide();
      },
      error: (err: any) => {
        this.spinner.hide();
        this.dataTable = [];
      }
    });
  }

  setParams(page) {
    const params = {
      ...this.filterParams,
      page,
      maxSize: 10,
      engineIds: this.dataOrganizations
    };
    return params;
  }

  submitFilter(filterParams) {
    this.filterParams = { ...filterParams };
    this.getListData(1);
  }

  moveToUpload(): void {
    this.router.navigate(['/admin/audio-management/upload-audio']);
  }

  handleStatusColor(status: string | undefined) {
    return {
      'color-black-text': (status ? status : '') === this.audioStatus.PENDING,
      'color-red': (status ? status : '') === this.audioStatus.ERROR,
      'color-yellow': (status ? status : '') === this.audioStatus.HANDLING,
      'color-active-2': (status ? status : '') === this.audioStatus.DONE,
      'color-gray-2': (status ? status : '') === this.audioStatus.IGNORE
    };
  }

  showHistory(rowData) {
    this.audioId = rowData.id;
    this.isShowHistory = true;
  }

  changeHistoryVisible(visible) {
    this.isShowHistory = visible;
  }

  handleShowFilter() {
    this.isShowFilter = !this.isShowFilter;
  }

  previousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      this.getListData(this.pageIndex);
    }
  }

  nextPage() {
    this.pageIndex++;
    this.getListData(this.pageIndex);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
