import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { StorageService } from '@shared/service/storage.service';
import { INTERNAL_REPORTS } from '@admin/config/side-bar/side-bar.config';

@Injectable({
  providedIn: 'root'
})
export class ReportAccessGuard implements CanActivate {
  private storageService = inject(StorageService);

  private router = inject(Router);

  private readonly hiddenReportByMasterOrg = ['volumeReportConfig'];
  private readonly hiddenReportByMemberOrg = ['reconciliationReportConfig'];

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const segments = route.pathFromRoot
      .map(segment => segment.url.map(url => url.path))
      .flat()
      .filter(path => path);

    const reportPath = segments[segments.length - 1];

    if (!reportPath) {
      return true;
    }

    const isMasterOrg = this.storageService.isMasterOrg();
    const restrictedReport = INTERNAL_REPORTS.find(r => r.url.includes(reportPath));

    if (restrictedReport) {
      const isRestricted = isMasterOrg
        ? this.hiddenReportByMasterOrg.includes(restrictedReport.field)
        : this.hiddenReportByMemberOrg.includes(restrictedReport.field);

      if (isRestricted) {
        return this.router.createUrlTree(['/admin']);
      }
    }

    return true;
  }
}
