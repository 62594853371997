import { INTERNAL_REPORTS } from '@admin/config/side-bar/side-bar.config';
import { EMBED_REPORT } from '@admin/constant/report/embed-report.constant';
import { IMenuItem } from '@admin/models/side-bar/side-bar.model';
import { AdminService } from '@admin/services/admin/admin.service';
import { ReportService } from '@admin/services/report/report.service';
import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@core/auth/services/auth.service';
import { LayoutService } from '@core/layout/services/layout.service';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { StorageService } from '@shared/service/storage.service';
import { cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription, startWith } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { PermissionsService } from './../../../../shared/service/permission.service';
import { HIDDEN_REPORT_BY_MASTER_ORG, HIDDEN_REPORT_BY_MEMBER_ORG } from '@shared/constant/internal-report.constant';
import { UNCHECK_CONFIG_REPORT } from '@shared/constant';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  storageService = inject(StorageService);
  router = inject(Router);

  @Output() changeEmbed = new EventEmitter();
  private destroy$ = new Subject<void>();
  isHide: boolean;

  embedReport: any[] = [];
  internalReportSetting: {};
  itemsRouter: string[] = [];
  userInfo: any = this.storageService.getUserInfo();
  menus: IMenuItem[] = [
    {
      level: 1,
      title: 'dashboard.dashboard',
      icon: 'pie-chart',
      open: false,
      selected: false,
      disabled: false,
      url: 'dashboard',
      // header: 'common.side_bar.agent',
      rights: [RIGHTS_CODE.RPT_005]
    },
    {
      level: 1,
      title: 'sidebar.handling_audio',
      icon: 'audio',
      open: false,
      selected: false,
      disabled: false,
      url: 'audio-management',
      header: 'common.side_bar.agent',
      hiddenOnOrgowns: true,
      rights: [RIGHTS_CODE.EVAL_000, RIGHTS_CODE.EVAL_001, RIGHTS_CODE.EVAL_002]
    },
    {
      level: 1,
      title: 'sidebar.evaluation_ticket.title',
      icon: 'carry-out',
      open: false,
      selected: false,
      disabled: false,
      url: 'evaluation',
      hiddenOnOrgowns: true,
      rights: [RIGHTS_CODE.EVAL_003, RIGHTS_CODE.EVAL_004, RIGHTS_CODE.EVAL_005, RIGHTS_CODE.EVAL_006, RIGHTS_CODE.EVAL_012],
      children: [
        {
          level: 2,
          title: 'sidebar.evaluation_ticket.list_of_ticket',
          icon: 'bars',
          open: false,
          selected: false,
          disabled: false,
          url: 'evaluation/ticket-list',
          rights: [RIGHTS_CODE.EVAL_003, RIGHTS_CODE.EVAL_004, RIGHTS_CODE.EVAL_005, RIGHTS_CODE.EVAL_006],
          children: [
            {
              level: 3,
              title: 'sidebar.evaluation_ticket.child_1',
              icon: '',
              open: false,
              selected: false,
              disabled: false,
              url: 'evaluation/ticket-list',
              rights: [RIGHTS_CODE.EVAL_003]
            },
            {
              level: 3,
              title: 'sidebar.evaluation_ticket.unassigned_ticket',
              icon: '',
              open: false,
              selected: false,
              disabled: false,
              url: 'evaluation/ticket-unassigned',
              rights: [RIGHTS_CODE.EVAL_004]
            },
            {
              level: 3,
              title: 'sidebar.evaluation_ticket.assigned_tickets',
              icon: '',
              open: false,
              selected: false,
              disabled: false,
              url: 'evaluation/ticket-assigned',
              rights: [RIGHTS_CODE.EVAL_005]
            },
            {
              level: 3,
              title: 'sidebar.evaluation_ticket.completed_tickets',
              icon: '',
              open: false,
              selected: false,
              disabled: false,
              url: 'evaluation/ticket-complete-assigned',
              rights: [RIGHTS_CODE.EVAL_006]
            }
          ]
        },
        {
          level: 2,
          title: 're_evaluation.title',
          icon: 'group',
          open: false,
          selected: false,
          disabled: false,
          url: 'evaluation/re-evaluation-ticket',
          rights: [RIGHTS_CODE.EVAL_012]
        }
      ]
    },
    // {
    //   disabled: false,
    //   icon: 'pic-left',
    //   level: 1,
    //   open: false,
    //   selected: false,
    //   title: EMBED_REPORT.REPORT_TITLE,
    //   url: 'report',
    //   // header: EMBED_REPORT.REPORT_HEADER,
    //   children: []
    // },
    {
      disabled: false,
      icon: 'history',
      level: 1,
      open: false,
      selected: false,
      title: 'action_history.title',
      url: 'action-history',
      hiddenOnOrgowns: true,
      rights: [RIGHTS_CODE.LOG_000]
    },
    {
      disabled: false,
      icon: 'home',
      level: 1,
      open: false,
      selected: false,
      title: 'org.title',
      url: 'org-management',
      header: 'org.header',
      rights: [RIGHTS_CODE.ORG_000]
    },
    {
      disabled: false,
      icon: 'user',
      level: 1,
      open: false,
      selected: false,
      title: 'user.title',
      url: 'user-management',
      rights: [RIGHTS_CODE.USER_000]
    },
    {
      disabled: false,
      icon: 'solution',
      level: 1,
      open: false,
      selected: false,
      title: 'right_role.title',
      url: 'right-role',
      rights: [RIGHTS_CODE.ROLE_000]
    }
  ];
  menusOrigin = cloneDeep(this.menus);
  listOrganizations = [];
  organizationsForm: FormGroup;
  valueChangesSubscription: Subscription | undefined;

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private adminService: AdminService,
    private loading: NgxSpinnerService,
    private reportService: ReportService,
    private organizationsGlobalService: OrganizationsGlobalService,
    private formBuilder: FormBuilder,
    public permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.getListOrganizations();
    this.organizationsForm = this.formBuilder.group({
      organizations: [[]]
    });
    this.sendDataOrgFromComponent();
    this.layoutService.IsShow.subscribe(res => {
      this.isHide = res;
    });
    this.reportService.validateOrgPricePackage();
    this.initRouterSubscribe();
    this.getEmbedList();
    this.getInternalReportList();
    this.selectCurrentItem();
    this.initStorageChanged();
    this.routingCheck();
  }

  protected isHomePage = this.router.url === '/admin/org-owns';
  routingCheck(): void {
    this.isHomePage = this.router.url === '/admin/org-owns' || !this.userInfo.engine?._id;
    if (this.router.url === '/admin/org-owns') this.layoutService.toggleMenu(false);
    if (!this.userInfo.engine?._id) this.menus = this.menusOrigin.filter((e: IMenuItem) => !e.hiddenOnOrgowns);
    else this.menus = cloneDeep(this.menusOrigin);
  }

  initStorageChanged() {
    this.storageService.getStorageChanged().subscribe(() => {
      this.userInfo = this.storageService.getUserInfo();
    });
  }

  initRouterSubscribe() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.routingCheck();
        this.selectCurrentItem();
        this.getEmbedList();
        this.getInternalReportList();
      }
    });
  }

  getInternalReportList(): void {
    this.reportService
      .getInternalList()
      .pipe(
        finalize(() => {
          this.selectCurrentItem();
        })
      )
      .subscribe({
        next: (res: any) => {
          this.internalReportSetting = res.data ? res.data : {};
          this.initInternalItems(this.internalReportSetting);
        },
        error: (err: any) => {}
      });
  }

  initInternalItems(internalReportSetting: {}): void {
    if (internalReportSetting === undefined) {
      return;
    }
    const reportIndex = this.menus.length;

    INTERNAL_REPORTS.forEach(r => {
      const isMasterOrg = this.storageService.isMasterOrg();
      if (
        (isMasterOrg && HIDDEN_REPORT_BY_MASTER_ORG.includes(r.field)) ||
        (!isMasterOrg && HIDDEN_REPORT_BY_MEMBER_ORG.includes(r.field))
      ) {
        return;
      }
      if (!this.permissionsService.canView(r.rights ? r.rights : [])) {
        return;
      }
      if (UNCHECK_CONFIG_REPORT.includes(r.field)) {
        this.menus.push({ ...r, header: '' });
        return;
      }
      if (!internalReportSetting[r.field] || !internalReportSetting[r.field].enabled) {
        return;
      }
      this.menus.push({ ...r, header: '' });
    });
    if (this.menus.length >= reportIndex && this.menus[reportIndex]) {
      this.menus[reportIndex].header = 'sidebar.report.title_2';
    }
  }

  selectCurrentItem() {
    this.itemsRouter = this.router.url.split('/');
    this.itemsRouter.splice(0, 2);
    this.markMenu(this.menus);
  }

  collapseOtherGroup(menu) {
    const currentStatus = menu.open;
    if (currentStatus) {
      return;
    }
    this.collapseGroup(this.menus, menu);
  }

  collapseGroup(menus, menu): boolean {
    let result = false;
    for (let i = 0; i < menus.length; i++) {
      const e = menus[i];
      if (e['children'] && e['children'].length) {
        if (this.collapseGroup(e['children'], menu)) {
          e.open = true;
          result = true;
          continue;
        }
      }
      if (e.url === menu.url) {
        e.open = true;
        result = true;
        continue;
      }
      e.open = false;
    }
    return result;
  }

  markMenu(menus: any[]): boolean {
    for (let i = 0; i < menus.length; i++) {
      const e = menus[i];
      if (e['children'] && e['children'].length) {
        if (this.markMenu(e['children'])) {
          e.open = true;
          return true;
        }
      }
      if (this.itemsRouter.includes('upload-audio') && e.url === 'audio-management') {
        e.selected = true;
        return true;
      }
      if (e.url.split('/').slice(-1)[0] === this.itemsRouter.slice(-1)[0]) {
        e.selected = true;
        return true;
      }
      e.open = false;
      e.selected = false;
    }
    return false;
  }

  getEmbedList() {
    this.reportService
      .getEmbedList()
      .pipe(
        finalize(() => {
          this.selectCurrentItem();
        })
      )
      .subscribe({
        next: (res: any) => {
          if (!res.data || !res.data.groups) {
            return;
          }
          this.embedReport = res.data.groups;
          this.initEmbedItems();
        },
        error: (err: any) => {}
      });
  }

  initEmbedItems() {
    const i = this.menus.findIndex(e => e.title === EMBED_REPORT.REPORT_TITLE);
    if (i === -1) {
      return;
    }
    this.menus[i].children = this.embedReport.map(({ name, pages }) => ({
      isEmbedReport: true,
      level: 2,
      title: `sidebar.report.group.${name}`,
      icon: 'profile',
      open: false,
      selected: false,
      disabled: false,
      url: `${EMBED_REPORT.REPORT_URL}/${name}`,
      children: pages.map(({ name, displayName }) => ({
        isEmbedReport: true,
        level: 3,
        title: `sidebar.report.${displayName}`,
        icon: 'file',
        open: false,
        selected: false,
        disabled: false,
        url: `${EMBED_REPORT.REPORT_URL}/${name}`,
        name
      })),
      name
    }));
    this.changeEmbed.emit(this.embedReport);
  }

  handleAction(item: string) {
    if (item === 'Logout') {
      this.loading.show();
      this.authService.logout().subscribe({
        next: res => {
          if (res.success) {
            this.storageService.removeToken();
            this.storageService.removeUserInfo();
            this.adminService.removeStorageV1();
            this.menus = cloneDeep(this.menusOrigin);
            this.router.navigate(['/']);
            this.loading.hide();
          }
        },
        error: err => {
          this.loading.hide();
          this.router.navigate(['/']);
        }
      });
    }
  }

  setting() {
    window.open('/setting', '_blank');
  }

  getListOrganizations() {
    this.layoutService.getListOrganizations().subscribe({
      next: (res: any) => {
        this.listOrganizations = res.data;
      },
      error: (err: any) => {}
    });
  }

  navigatePage() {
    this.router.navigate(['/admin/org-owns']);
  }

  sendDataOrgFromComponent() {
    const engineId = this.storageService.getUserInfo().org._id;
    this.valueChangesSubscription = this.organizationsForm
      .get('organizations')
      ?.valueChanges.pipe(startWith(engineId))
      .subscribe(value => {
        if (value === engineId || value.length === 0) {
          const engineIds = [engineId];
          this.organizationsGlobalService.sendOrganizations({ org: engineIds, IsOrg: false });
        } else {
          this.organizationsGlobalService.sendOrganizations({ org: value, IsOrg: true });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
