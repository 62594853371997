import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { debounceTime } from 'rxjs/operators';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject, Subscription, take, takeUntil, timer } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { SHOW_BY } from '@admin/constant/report/violation-sumary';
import { REPORT_BY } from '@admin/constant/report/volumn';
import { DOWNLOAD } from '@shared/constant/download';

import { ASSIGNED_CALL_REPORT_BY, ASSIGNED_CALL_REPORT_BYS } from '@admin/constant/report/assigned-call-by-qc';
import { AssignedCallByQcReportService } from '@admin/services/report-internal/assigned-call-by-qc-report.service';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { DownloadService } from '@shared/service/download.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { PermissionsService } from '@shared/service/permission.service';
import { SocketService } from '@shared/service/socket.service';
import { startOfToday, subDays } from 'date-fns';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-search-assigned-call-by-qc-report',
  templateUrl: './search-assigned-call-by-qc-report.component.html',
  styleUrls: ['./search-assigned-call-by-qc-report.component.scss']
})
export class SearchAssignedCallByQcReportComponent implements OnInit, OnDestroy {
  ASSIGNED_CALL_REPORT_BY = cloneDeep(ASSIGNED_CALL_REPORT_BY);
  RIGHTS_CODE = RIGHTS_CODE;
  @Output() dataSubmitForm = new EventEmitter<any>();
  @Input() lastSyncAt: string;
  searchForm: FormGroup;
  submitted = false;
  listShowBy: SelectOption[] = [
    { label: 'report.violation.show_by.day', value: SHOW_BY.DAY },
    { label: 'report.violation.show_by.month', value: SHOW_BY.MONTH },
    { label: 'report.violation.show_by.quarter', value: SHOW_BY.QUARTER }
  ];
  listReportBy: SelectOption[] = cloneDeep(ASSIGNED_CALL_REPORT_BYS);

  destroyObservable = new Subject<void>();
  exportId = uuidv4();
  exportParams: any = {};
  defaultEndDate = startOfToday();
  defaultStartDate = subDays(this.defaultEndDate, 6);
  dataOrganizations: string[];
  isDisabledKW = false;
  private subscription: Subscription;
  listQcName: any[];
  listQcId: any[];

  constructor(
    private formBuilder: FormBuilder,
    public formatTimeService: FormatTimeService,
    public downloadService: DownloadService,
    private socketService: SocketService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private assignedCallByQcReportService: AssignedCallByQcReportService,
    private organizationsGlobalService: OrganizationsGlobalService,
    public permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.handleAutoGetDataSelect('reportBy');
    this.initDownloadService();
    this.checkOrganizations();
    this.getFilter();
  }

  checkOrganizations() {
    this.subscription = this.organizationsGlobalService.valueOrganizations$.pipe(takeUntil(this.destroyObservable)).subscribe({
      next: result => {
        if (result.org) {
          this.dataOrganizations = result.org;
          this.isDisabledKW = result.IsOrg;
          this.emitDataForm();
        }
      }
    });
  }

  initForm(): void {
    this.searchForm = this.formBuilder.group({
      reportBy: [REPORT_BY.CONTACT_DATE],
      contactDate: [[this.defaultStartDate, this.defaultEndDate]],
      qcNames: [[]],
      qcIds: [[]]
    });
  }

  handleAutoGetDataSelect(selectForm: string): void {
    this.searchForm
      ?.get(selectForm)
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(() => {
        this.emitDataForm();
      });
  }

  getFilter() {
    const params = this.setupFilterParams();
    this.assignedCallByQcReportService.getFilter(params).subscribe({
      next: res => {
        if (!res) {
          return;
        }
        this.listQcName = (res.data && res.data['qc_username']) || [];
        this.listQcId = (res.data && res.data['qc_id']) || [];
      }
    });
  }

  emitDataForm(): void {
    const formData = this.setupParams();
    this.dataSubmitForm.emit(formData);
  }

  onSubmit() {
    this.submitted = true;
    const params = this.setupParams();
    this.dataSubmitForm.emit(params);
  }

  setupFilterParams() {
    return {
      reportBy: this.searchForm.get('reportBy')?.value || null,
      dateFrom: this.searchForm.get('contactDate')?.value?.['0'] ? this.searchForm.get('contactDate')?.value?.['0'] : this.defaultStartDate,
      dateTo: this.searchForm.get('contactDate')?.value?.['1'] ? this.searchForm.get('contactDate')?.value?.['1'] : this.defaultEndDate,
      engineIds: this.dataOrganizations,
      fields: ['qc_id', 'qc_username']
    };
  }

  setupParams() {
    const { reportBy, contactDate, qcNames, qcIds } = this.searchForm.value;
    let params: any = {};
    if (reportBy) {
      params.reportBy = reportBy;
    }
    params.dateFrom = contactDate.length && contactDate[0] ? contactDate[0] : this.defaultStartDate;
    params.dateTo = contactDate.length && contactDate[1] ? contactDate[1] : this.defaultEndDate;
    params.engineId = this.dataOrganizations;
    params.page = 1;
    if (qcNames && qcNames.length) {
      params.qcUsernames = qcNames;
    }
    if (qcIds && qcIds.length) {
      params.qcIds = qcIds;
    }
    return params;
  }

  resetForm() {
    const defaultValues = {
      contactDate: [this.defaultStartDate, this.defaultEndDate],
      reportBy: REPORT_BY.CONTACT_DATE,
      qcNames: [],
      qcIds: []
    };
    this.submitted = false;
    this.searchForm.patchValue(defaultValues);
  }

  initDownloadService() {
    this.downloadService.isDownloadHandling = false;
    this.downloadService.downloadFileObservable.pipe(takeUntil(this.destroyObservable)).subscribe(data => {
      this.downloadService.handleDownloadData(data);
      this.socketService.reportLeaveRoom(this.exportId);
      timer(DOWNLOAD.DELAY_TIME)
        .pipe(take(1))
        .subscribe(() => {
          this.downloadService.isDownloadHandling = false;
        });
    });
  }

  async exportToExcel() {
    this.socketService.reportEmitRoom(this.exportId);
    this.exportParams = this.setupParams();
    if (this.exportId) {
      this.exportParams['exportId'] = this.exportId;
    }
    this.assignedCallByQcReportService.exportReport(this.exportParams).subscribe({
      next: (res: any) => {
        this.downloadService.isDownloadHandling = true;
      },
      error: (err: any) => {
        this.notificationService.create(
          'error',
          this.translateService.instant('report.export.title'),
          this.translateService.instant('report.export.error')
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyObservable.next();
    this.destroyObservable.complete();
    this.socketService.reportLeaveRoom(this.exportId);
    this.downloadService.isDownloadHandling = false;
    this.subscription.unsubscribe();
  }
}
