import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGE } from '@shared/constant/language';
import { StorageService } from '@shared/service/storage.service';
import { NzI18nService, en_US, vi_VN } from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss']
})
export class SwitchLanguageComponent implements OnInit {
  switchValue = false;

  constructor(private translateService: TranslateService, private storageService: StorageService, private i18n: NzI18nService) {}

  ngOnInit(): void {
    this.switchValue = this.storageService.getLang() !== LANGUAGE.VI;
    this.i18n.setLocale(this.storageService.getLang() === LANGUAGE.VI ? vi_VN : en_US);
  }

  changeLang(lang: boolean): void {
    // const currentLanguage = this.translateService.currentLang;
    const selectedLang = !lang ? LANGUAGE.VI : LANGUAGE.EN;
    this.translateService.use(selectedLang);
    this.storageService.setLang(selectedLang);
    this.storageService.setCurrentLanguageSubject(selectedLang);
    this.storageService.setLangV1(selectedLang);
    this.i18n.setLocale(selectedLang === LANGUAGE.VI ? vi_VN : en_US);
    this.switchValue = !this.switchValue;
  }
}
