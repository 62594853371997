// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.current-page {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 4px 14px;
  border-radius: 8px;
  background: #e6f2fe;
  color: #2475cb;
  font-size: 14px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhZ2luYXRvci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHQTtFQUNFLGFBQUE7RUFDQSxtQkFBQTtFQUNBLFlBQUE7RUFDQSxpQkFBQTtFQUNBLGtCQUFBO0VBQ0EsbUJBQUE7RUFDQSxjQUFBO0VBQ0EsZUFBQTtBQUZGIiwiZmlsZSI6InBhZ2luYXRvci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJ21peGlucyc7XG5AaW1wb3J0ICd2YXJpYWJsZSc7XG5cbi5jdXJyZW50LXBhZ2Uge1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBoZWlnaHQ6IDMycHg7XG4gIHBhZGRpbmc6IDRweCAxNHB4O1xuICBib3JkZXItcmFkaXVzOiA4cHg7XG4gIGJhY2tncm91bmQ6ICNlNmYyZmU7XG4gIGNvbG9yOiBtYXAtZ2V0KCRjb2xvciwgcHJpbWFyeSk7XG4gIGZvbnQtc2l6ZTogbWFwLWdldCgkZm9udC1zaXplLCBzbSk7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/partial/paginator/paginator.component.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AAFF;AACA,osBAAosB","sourcesContent":["@import 'mixins';\n@import 'variable';\n\n.current-page {\n  display: flex;\n  align-items: center;\n  height: 32px;\n  padding: 4px 14px;\n  border-radius: 8px;\n  background: #e6f2fe;\n  color: map-get($color, primary);\n  font-size: map-get($font-size, sm);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
