import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LayoutService } from '@core/layout/services/layout.service';
import { PermissionsService } from '@shared/service/permission.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  router = inject(Router);
  permissionsService = inject(PermissionsService);

  isToggleMenu = false;
  breadcrumb: string;
  titlePage: string;
  breadCrumbs: string[] = [];
  embedReport: any[] = [];
  isTranslate = false;
  subscription: Subscription;

  constructor(
    private layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService
  ) {}

  protected isShowIcon = this.router.url !== '/admin/org-owns' && !!this.permissionsService.rights;

  ngOnInit() {
    this.subscribeBreadcrumb();
    this.handleShowSideBar();
    this.activatedRoute.data.subscribe(data => {
      this.breadcrumb = data['breadcrumb'];
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleMenu() {
    this.layoutService.toggleMenu();
  }

  subscribeBreadcrumb() {
    this.breadCrumbs = this.router.url.split('/');
    this.breadCrumbs.splice(0, 1);
    if (!this.breadCrumbs.find(e => e === 'report')) {
      this.translateBreadCrumb();
    }
    this.subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.breadCrumbs = this.router.url.split('/');
      this.breadCrumbs.splice(0, 1);
      this.translateBreadCrumb();
      this.isShowIcon = this.router.url !== '/admin/org-owns' && !!this.permissionsService.rights;
    });
  }

  translateBreadCrumb() {
    this.breadCrumbs.forEach((e, i) => {
      switch (e) {
        case 'admin': {
          this.breadCrumbs[i] = 'sidebar.admin';
          break;
        }
        case 'evaluation': {
          this.breadCrumbs[i] = 'sidebar.evaluation_ticket.title';
          break;
        }
        case 'ticket-list': {
          this.breadCrumbs[i] = 'sidebar.evaluation_ticket.child_1';
          break;
        }
        case 'audio-management': {
          this.breadCrumbs[i] = 'sidebar.handling_audio';
          break;
        }
        case 'upload-audio': {
          this.breadCrumbs[i] = 'sidebar.upload_audio';
          break;
        }
        case 'dashboard': {
          this.breadCrumbs[i] = 'dashboard.dashboard';
          break;
        }
        case 'ticket-info': {
          this.breadCrumbs[i] = 'sidebar.evaluation_ticket.ticket_info';
          this.breadCrumbs.pop();
          break;
        }
        case 're-evaluation-ticket': {
          this.breadCrumbs[i] = 're_evaluation.title';
          break;
        }
        case 'report-internal': {
          this.breadCrumbs[i] = 'sidebar.report.title';
          break;
        }
        case 'violation-summary': {
          this.breadCrumbs[i] = 'report.violation.title';
          break;
        }
        case 'volume': {
          this.breadCrumbs[i] = 'report.volumn.title';
          break;
        }
        case 'assigned-call': {
          this.breadCrumbs[i] = 'report.assigned_call.title';
          break;
        }
        case 'assigned-call-by-qc': {
          this.breadCrumbs[i] = 'report.assigned_call_by_qc.title';
          break;
        }
        case 'reconciliation': {
          this.breadCrumbs[i] = 'report.reconciliation.title';
          break;
        }
        case 'violation-by-agent': {
          this.breadCrumbs[i] = 'report.violation_by_agent.title';
          break;
        }
        case 'call-analysis': {
          this.breadCrumbs[i] = 'report.call_analysis.title';
          break;
        }
        case 'attitude-kw-bucket': {
          this.breadCrumbs[i] = 'common.filter.attitude_kw.title';
          break;
        }
        case 'action-history': {
          this.breadCrumbs[i] = 'action_history.title';
          break;
        }
        case 'right-role': {
          this.breadCrumbs[i] = 'right_role.title';
          break;
        }
        case 'ticket-unassigned': {
          this.breadCrumbs[i] = 'sidebar.evaluation_ticket.unassigned_ticket';
          break;
        }
        case 'ticket-assigned': {
          this.breadCrumbs[i] = 'sidebar.evaluation_ticket.assigned_tickets';
          break;
        }
        case 'ticket-complete-assigned': {
          this.breadCrumbs[i] = 'sidebar.evaluation_ticket.completed_tickets';
          break;
        }
        case 'org-management': {
          this.breadCrumbs[i] = 'org.title';
          break;
        }
        case 'org-owns': {
          this.breadCrumbs[i] = 'org_owns.title';
          break;
        }
        case 'user-management': {
          this.breadCrumbs[i] = 'user.title';
          break;
        }
        case 'report': {
          this.breadCrumbs[i] = 'sidebar.report.title';
          if (this.breadCrumbs.length === i + 1 || !this.embedReport.length) {
            break;
          }
          let currentReport: any;
          for (let j = 0; j < this.embedReport.length; j++) {
            const e = this.embedReport[j];
            currentReport = e.pages.find(f => f.name === this.breadCrumbs[i + 1]);
            if (currentReport) break;
          }
          this.breadCrumbs[i + 1] = currentReport.displayName ? 'sidebar.report.' + currentReport.displayName : '';
          break;
        }
        default: {
          break;
        }
      }
    });
    this.translateService.get(this.breadCrumbs[this.breadCrumbs.length - 1]).subscribe((res: string) => {
      this.titleService.setTitle(`${res} - VNLP Virtual QC`);
    });
    this.isTranslate = true;
  }

  changeEmbed(embedReport) {
    this.embedReport = embedReport;
    this.translateBreadCrumb();
  }

  handleShowSideBar() {
    this.layoutService.IsShow.subscribe(res => {
      this.isToggleMenu = res;
    });
  }
}
