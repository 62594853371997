import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ViolationSummaryComponent } from '@admin/components/report-internal/violation-summary/violation-summary.component';
import { VolumnComponent } from '@admin/components/report-internal/volumn/volumn.component';
import { CallAnalysisComponent } from '@admin/components/report-internal/call-analysis/call-analysis.component';
import { ViolationByAgentComponent } from '@admin/components/report-internal/violation-by-agent/violation-by-agent.component';
import { AttitudeKwBucketComponent } from '@admin/components/report-internal/attitude-kw-bucket/attitude-kw-bucket.component';
import { AssignedCallReportComponent } from './assigned-call-report/assigned-call-report.component';
import { AssignedCallByQcReportComponent } from './assigned-call-by-qc-report/assigned-call-by-qc-report.component';
import { ReportAccessGuard } from '@core/guards';

const routes: Routes = [
  {
    path: 'violation-summary',
    component: ViolationSummaryComponent
  },
  {
    path: 'volume',
    component: VolumnComponent,
    canActivate: [ReportAccessGuard]
  },
  {
    path: 'call-analysis',
    component: CallAnalysisComponent
  },
  {
    path: 'violation-by-agent',
    component: ViolationByAgentComponent
  },
  {
    path: 'attitude-kw-bucket',
    component: AttitudeKwBucketComponent
  },
  {
    path: 'assigned-call',
    component: AssignedCallReportComponent
  },
  {
    path: 'assigned-call-by-qc',
    component: AssignedCallByQcReportComponent
  },
  {
    path: 'reconciliation',
    loadChildren: () => import('./reconciliation-report/reconciliation-report.module').then(m => m.ReconciliationReportModule),
    canActivate: [ReportAccessGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportInternalRoutingModule {}
