import { KPI_REASON_TYPE, KPI_REFERINFORMATION_TRACE } from '@admin/constant/evaluation/evaluation.constant';
import { TraceReason } from '@admin/models/evaluation/est-kpi';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-trace-container',
  templateUrl: './trace-container.component.html',
  styleUrls: ['./trace-container.component.scss']
})
export class TraceContainerComponent implements OnInit {
  @Input() kpiType = '';
  @Input() traceReasons: TraceReason[] = [];
  traces: any[] = [];
  tracesCompare: any[] = [];
  traceCols: any[] = [];
  traceColsCompare: any[] = [];
  hasChildIntent = true;

  protected kpiReasonType = KPI_REASON_TYPE

  parentNlpConditionName: string | undefined = '';

  childNlpConditionName: string | undefined = '';

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['kpiType'] && changes['kpiType'].currentValue) {
      this.setUpTable();
    }
  }

  setUpTable() {
    switch (this.kpiType) {
      case KPI_REASON_TYPE.SILENT: {
        this.setupSilentTable();
        break;
      }
      case KPI_REASON_TYPE.KEYWORD: {
        this.setupKeywordTable();
        break;
      }
      case KPI_REASON_TYPE.NLP: {
        this.setupNlpTable();
        break;
      }
      case KPI_REASON_TYPE.REFER_INFORMATION: {
        this.setUpReferTable();
        break;
      }
      default:
        break;
    }
  }

  setupSilentTable() {
    this.traces = this.traceReasons[0].traces;
    this.traceCols = [
      { title: 'kpi_result.traces.no_silent', field: 'index', textAlign: 'center', template: 'DefaultCol', width: '120px' },
      { title: 'kpi_result.traces.time_stamp', field: 'silentTime', textAlign: 'center', template: 'Hyperlink', width: '' },
      { title: 'kpi_result.traces.silence_time', field: 'silentDuration', textAlign: 'center', template: 'DefaultCol', width: '150px' }
    ];
  }

  setupKeywordTable() {
    this.traces = this.traceReasons[0].traces.reduce((sorted, e) => {
      if (e.numOfShowUps === 0) {
        sorted.push(e);
        return sorted;
      }
      let index = 0;
      while (index < sorted.length && e.numOfShowUps > sorted[index].numOfShowUps && sorted[index].numOfShowUps !== 0) {
        index++;
      }
      sorted.splice(index, 0, e);
      return sorted;
    }, []);
    this.traceCols = [
      { title: 'kpi_result.traces.keyword', field: 'keyword', textAlign: '', template: 'DefaultCol', width: '' },
      {
        title: 'kpi_result.traces.num_of_show_up',
        field: 'numOfShowUps',
        textAlign: 'center',
        template: 'NumberKeywordShow',
        width: '150px'
      }
    ];
  }

  setupNlpTable() {
    if (!this.traceReasons[0]) {
      return;
    }
    this.hasChildIntent = !!this.traceReasons[1];
    const isIntentType = this.traceReasons[0].traceType.split('_').slice(-1)[0] === 'INTENT';
    this.traces = this.setUpNlpTraces(this.traceReasons[0].traces, isIntentType);
    this.traceCols = this.setUpNlpCols(isIntentType);
    this.parentNlpConditionName = this.traceReasons[0].name;

    if (!this.hasChildIntent) {
      return;
    }
    const isIntentTypeCompare = this.traceReasons[1].traceType.split('_').slice(-1)[0] === 'INTENT';
    this.tracesCompare = this.setUpNlpTraces(this.traceReasons[1].traces, isIntentTypeCompare);
    this.traceColsCompare = this.setUpNlpCols(isIntentTypeCompare);
    this.childNlpConditionName = this.traceReasons[1].name;
  }

  setUpNlpTraces(traces: any[], isIntentType: boolean): any[] {
    const traceResult: any[] = [];
    if (!traces.length || !traces.find(t => t['numOfShowUps'])) {
      return traceResult;
    }
    traces.forEach(t => {
      if (!t['numOfShowUps']) {
        t.timestamp = '-';
        traceResult.push(t);
        return;
      }
      t['details'].forEach(e => {
        isIntentType ? (e.intent = t['intent']) : (e.keyword = t['keyword']);
        let index = 0;
        while (index < traceResult.length && e.startTime > traceResult[index].startTime && traceResult[index].timestamp !== '-') {
          index++;
        }
        traceResult.splice(index, 0, e);
      });
    });
    return traceResult;
  }

  setUpNlpCols(isIntentType: boolean): any[] {
    let cols: any[] = [];
    const col = isIntentType
      ? { title: 'kpi_result.traces.intent', field: 'intent', textAlign: '', template: 'DefaultCol', width: '' }
      : { title: 'kpi_result.traces.keyword', field: 'keyword', textAlign: '', template: 'DefaultCol', width: '' };

    cols = [
      col,
      {
        title: 'kpi_result.traces.time_stamp',
        field: 'timestamp',
        textAlign: 'center',
        template: 'Hyperlink',
        width: '180px'
      }
    ];
    return cols;
  }

  private setUpReferTable(): void {
    const tableData = this.handleReferData(this.traceReasons);
    this.traces = tableData.entities;
    this.traceCols = [
      { title: 'kpi_result.traces.entity', field: 'entity', textAlign: 'center', template: 'DefaultCol', width: '' },
      { title: 'kpi_result.traces.value', field: 'value', textAlign: 'center', template: 'Hyperlink', width: '' }
    ];

    this.tracesCompare = tableData.metadata;
    this.traceColsCompare= [
      { title: 'kpi_result.traces.crm', field: 'entity', textAlign: 'center', template: 'DefaultCol', width: '' },
      { title: 'kpi_result.traces.value', field: 'value', textAlign: 'center', template: 'DefaultCol', width: '' }
    ];
  }
  private handleReferData(traces: TraceReason[]): { entities: any[]; metadata: any[] } {
    const entities = traces.find(trace => trace.traceType === KPI_REFERINFORMATION_TRACE.ENTITIES)?.traces;
    const metadata = traces.find(trace => trace.traceType === KPI_REFERINFORMATION_TRACE.METADATA)?.traces;

    const entitiesData = !entities ? [] : entities.reduce((acc, entity) => {
      const transcriptDetails = entity.transcriptDetails.map((transcript, index) => {
        return {
          entity: entity.value,
          value: transcript.value || '-',
          startTime: transcript.startTime
        };
      });
      return [...acc, ...transcriptDetails];
    }, []);

    const metadataData = !metadata ? [] : metadata.map(meta => {
      return {
        entity: meta.value,
        value: meta.transcriptDetails[0]?.value || '-'
      };
    });

    return {
      entities: entitiesData,
      metadata: metadataData
    };
  }
}
