export const StatusCodeConstants = {
  200: 'OK',
  204: 'OK',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'permission denied',
  404: 'Not Found',
  500: 'Internal Server Error'
};

export const ErrorCodeConstants = {
  // Service AUTH
  'AUTH-00000100': 'error_handling.auth.username_or_password_is_incorrect',
  'AUTH-00000101': 'error_handling.auth.token_is_invalid',
  'AUTH-00000102': 'error_handling.auth.token_is_expired',
  'AUTH-00000103': 'error_handling.auth.unauthorized',
  'AUTH-00000104': 'error_handling.auth.not_change_password',
  'AUTH-00000106': 'error_handling.auth.unauthorized_org',
  'AUTH-00000107': 'error_handling.auth.old_password_not_correct',
  'AUTH-00000113': 'error_handling.auth.not_lang_send_mail',
  'AUTH-00000115': 'error_handling.auth.otp_still_alive_cant_send_mail',
  'AUTH-00000200': 'error_handling.auth.user_is_not_found',
  'AUTH-00000201': 'error_handling.auth.cannot_change_status',
  'AUTH-00000206': 'error_handling.auth.USER_IS_EXISTING',
  'AUTH-00000207': 'error_handling.auth.EMAIL_IS_EXISTING',
  'AUTH-00000217': 'error_handling.auth.ExistedUserCode',
  'AUTH-00000401': 'error_handling.auth.UNAUTHORIZED',
  'AUTH-00000403': 'error_handling.eval.USER_FORBIDDEN',
  'AUTH-00000406': 'error_handling.auth.ROLE_EXISTED',
  'AUTH-00000409': 'error_handling.auth.ROLE_NAME_IS_TOO_LONG',
  'AUTH-00001500': 'error_handling.auth.CANNOT_CREATE_ENGINE',
  'AUTH-00001501': 'error_handling.auth.ENGINE_NOT_FOUND',
  'AUTH-00001502': 'error_handling.auth.CANNOT_UPDATE_ENGINE',
  'AUTH-00001505': 'error_handling.auth.ENGINE_FAILED_ASSIGN_USER',
  'AUTH-00001510': 'error_handling.auth.EXISTED_ENGINE',
  'AUTH-00001508': 'error_handling.auth.CANNOT_GET_TOKEN_WITH_ENGINE',
  'AUTH-00002000': 'error_handling.auth.USER_CODE_IS_EXISTS',
  'AUTH-00002005': 'error_handling.auth.USERNAME_IS_EXISTS',
  'AUTH-00002015': 'error_handling.auth.USER_NOT_FOUND',
  'AUTH-00002020': 'error_handling.auth.username_or_password_is_incorrect',
  'AUTH-00002055': 'error_handling.auth.user_old_password_and_new_password_is_the_same',
  'AUTH-00002060': 'error_handling.auth.user_old_password_is_incorrect',
  'AUTH-00002070': 'error_handling.auth.user_assigned_other_engine',
  'AUTH-00002080': 'error_handling.auth.USER_NOT_FOUND',
  'AUTH-00003000': 'error_handling.auth.ROLE_EXISTED',
  'AUTH-00004000': 'error_handling.auth.PARAM_ERROR_USERNAME',
  'AUTH-00004002': 'error_handling.auth.PARAM_ERROR_USERCODE',
  'AUTH-00004003': 'error_handling.auth.PARAM_ERROR_EMAIL',
  'AUTH-00004004': 'error_handling.auth.PARAM_ERROR_PASSWORD',
  'AUTH-00004005': 'error_handling.auth.PARAM_ERROR_PHONE_NUMBER',
  'AUTH-00005505': 'error_handling.auth.EXISTED_ENGINE',
  'AUTH-00005530': 'error_handling.auth.CANNOT_CHANGE_STATUS_ASSIGN_ENGINE',
  // Service EVAL
  'EVAL-00001020': 'error_handling.eval.ENGINE_NOT_FOUND',
  'EVAL-00001040': 'error_handling.eval.REQUEST_BODY_IS_INVALID',
  'EVAL-00000403': 'error_handling.eval.USER_FORBIDDEN',
  'EVAL-00002105': 'error_handling.eval.ENGINE_NOT_FOUND',
  'EVAL-00002925': 'error_handling.eval.evaluation_assigned_another_qc',
  // Service Report
  'RPT-00000403': 'error_handling.report.USER_FORBIDDEN',
  'TOOL-00001003': 'error_handling.report.NOT_FOUND_EMAIL',
  'TOOL-00001004': 'error_handling.report.ERROR_FROM_AUTH_FOR_GET_EMAIL',
  'TOOL-00001005': 'error_handling.report.LIMIT_CREATED_JOB',

  // Upload audio
  'EVAL-00001620': 'error_handling.upload_audio.AUDIO_DURATION_TOO_LONG'
};
