export enum ReportConfigName {
  RECONCILIATION = 'reconciliationReportConfig',
  VOLUME = 'volumeReportConfig',
  ASSIGNED_CALLS = 'assignedCallsReportConfig',
  ASSIGNED_CALLS_BY_QC = 'assignedCallsByQCReportConfig',
  VIOLATION_CALLS_SUMMARY = 'violationCallsSummaryReportConfig',
  CALL_ANALYSIS = 'callStatisticReportConfig',
  ATTITUDE_KW_BUCKET = 'attitudeKWBucketReportConfig',
  VIOLATION_BY_AGENT = 'violationCallsByAgentReportConfig',
  VIOLATION_BY_AGENT_BY_BUCKET = 'violationCallsByBucketReportConfig'
}

export const UNCHECK_CONFIG_REPORT = [
  ReportConfigName.RECONCILIATION,
  ReportConfigName.VOLUME,
  ReportConfigName.ASSIGNED_CALLS,
  ReportConfigName.ASSIGNED_CALLS_BY_QC
];

export const HIDDEN_REPORT_BY_MASTER_ORG = [ReportConfigName.VOLUME];
export const HIDDEN_REPORT_BY_MEMBER_ORG = [ReportConfigName.RECONCILIATION];
