import { environment } from '@env/environment';

const VERSION = {
  v0: 'v0',
  v1: 'v1',
  v2: 'v2'
};
const ENV = {
  root: environment.vqc_v2.root,
  staticRoot: environment.staticRoot,
  media: environment.media,
  auth: environment.vqc_v2.auth,
  kpi: environment.vqc_v2.kpi,
  kpiV1: environment.vqc_v2.kpiV1,
  evaluationV1: environment.vqc_v2.evaluationV1,
  evaluationV2: environment.vqc_v2.evaluationV2,
  report: environment.vqc_v2.reportV1,
  toolReport: environment.vqc_v2.toolReportV1
};

const RESOURCES = {
  kpi: '/kpi-service',
  admin: `/api/${VERSION.v1}`
};

export const ENDPOINT = {
  root: `${ENV.root}`,
  auth: {
    login: `${ENV.auth}/auth/v2/login`,
    logout: `${ENV.auth}/auth/logout`,
    verify2faCode: `${ENV.auth}/${VERSION.v1}/auth/2fa`,
    resend2faCode: `${ENV.auth}/${VERSION.v1}/auth/resend-2fa`,
    organizations: `${ENV.auth}/organizations/list-engines`,
    listQC: `${ENV.auth}/users/list-qc`,
    refreshToken: `${ENV.auth}/auth/refresh-token`
  },
  user: {
    root: `${ENV.auth}/users`,
    getUsers: `${ENV.auth}/users/get-users`,
    getListUsers: `${ENV.auth}/users/list-username`,
    getDetailUser: 'get-detail',
    createUser: `${ENV.auth}/users/create`,
    updateUser: 'update',
    updateStatus: 'update-status',
    updateStatusByUserIds: `${ENV.auth}/users/update-status-by-user-ids`,
    resetPassword: 'reset-password',
    deleteUsers: `${ENV.auth}/users/delete-user-from-list-ids`,
    getListQC: `${ENV.auth}/users/list-qc`
  },
  report: {
    embedReport: `${ENV.kpiV1}/power-bi/embed-info`,
    embedList: `${ENV.kpiV1}/power-bi/list-page`,
    internalList: `${ENV.auth}/organizations/engines/config`,
    violationSummary: `${ENV.evaluationV2}/attitude-keywords/versions`,
    violationSearchEngine: `${ENV.evaluationV2}/engine-configs/violation-calls-summary-report-config`,
    selectViolation: `${ENV.report}/attitude-keyword-report/violation-calls-summary-filter-values`,
    reportViolationSummary: `${ENV.report}/attitude-keyword-report/violation-calls-summary`,
    volumn: `${ENV.report}/total-call-report/volume-total-call-and-duration`,
    assignedCallReport: `${ENV.report}/call-evaluation-assignment-report/total-call-assignment-statistic`,
    assignedCallByQcReport: `${ENV.report}/call-evaluation-assignment-report/call-assignment-by-qc-statistic`,
    callEvaluationAssignmentReport: `${ENV.report}/call-evaluation-assignment-report`,
    callAnalysis: `${ENV.report}/call-analysis-report/call-statistics`,
    orgPackageReport: `${ENV.report}/org-package-report`,
    usagePlansReport: `${ENV.report}/org-total-call-report`,
    callAnalysisToolReport: `${ENV.toolReport}/call-analysis-report/call-statistics`,
    reportViolationByAgentReport: `${ENV.report}/attitude-keyword-report/violation-calls-by-agent`,
    violationSearchEngineByAgentReport: `${ENV.evaluationV2}/engine-configs/violation-calls-by-agent-report-config`,
    selectViolationByAgentReport: `${ENV.report}/attitude-keyword-report/violation-calls-by-agent-filter-values`,
    attitudeKwBucket: `${ENV.report}/attitude-keyword-report/violation-calls-by-bucket`,
    attitudeKwBucketSearchEngine: `${ENV.evaluationV2}/engine-configs/violation-calls-by-bucket-report-config`,
    valueSelectAttitudeKwBucket: `${ENV.report}/attitude-keyword-report/violation-calls-by-bucket-filter-values`,
    filterAdvanced: `${ENV.report}/call-analysis-report/call-statistics-filter-values`,
    kpiName: `${ENV.report}/call-analysis-report/kpi-filter-values`,
    orgPricePackages: `${ENV.report}/org-package-report/actives?orgId=`,
    orgPricePackageRemainDuration: `${ENV.report}/org-package-report/remain-durations`
  },
  audio: {
    validateUpload: `${ENV.evaluationV2}/audios/upload/pre-check-manual-audios`,
    uploadManual: `${ENV.evaluationV2}/audios/upload/manual`,
    getListData: `${ENV.evaluationV2}/audios`,
    getHistory: `${ENV.evaluationV2}/audios`,
    getStatus: `${ENV.evaluationV1}/audio-status/count-by-statuses`,
    getMetadataConfig: `${ENV.kpiV1}/metadata-mapping/config`,
    reEvaluatedAudio: `${ENV.evaluationV2}/audios/:id/re-evaluate`,
    audioError: `${ENV.evaluationV2}/audio-status/evaluate-error`,
    reEvaluateAudio: `${ENV.evaluationV2}/audio-status/re-evaluate-error-audios`,
    bucketAndProduct: `${ENV.evaluationV2}/audios/get-list-bucket-and-product`,
    checkReEvaluated: `${ENV.evaluationV2}/audios/:audioId/kpi-attitude-versions`
  },
  dashboard: {
    callOverview: `${ENV.report}/total-call-report/visualization/total-call-and-duration-with-agent-overview`
  },
  actionHistory: {
    audioActionHistory: {
      getListData: `${ENV.evaluationV2}/audio-logs`
    },
    evaluationActionHistory: {
      getListData: `${ENV.evaluationV2}/evaluation-logs`
    },
    logEvaluationAction: {
      downloadAudio: `${ENV.evaluationV2}/evaluation-logs/log-download-audios`
    }
  },
  rightRole: {
    role: {
      getListData: `${ENV.auth}/roles/list-roles-from-org`,
      base: `${ENV.auth}/roles`
    },
    right: {
      getListData: `${ENV.auth}/rights/list`
    }
  },
  org: {
    getListData: `${ENV.auth}/organizations/engines`,
    getAssignableRoles: `${ENV.auth}/organizations/engines/assign/roles`,
    getAssignableUsers: `${ENV.auth}/organizations/engines/assign/users`
  },

  kpi: {
    config: `${ENV.kpi}/kpi-config`,
    listVersion: `${ENV.kpi}/kpi-config/list-version`,
    kpiName: `${ENV.kpi}/kpi-config/list-kpi-name-by-version`
  },
  nplEntity: {
    entity: `${ENV.root}/${RESOURCES.admin}/entity-types`,
    getList: `${ENV.root}/${RESOURCES.admin}/entity-types/get-list-entity`
  },
  evaluation: {
    baseUrlEvaluation: `${ENV.evaluationV2}/evaluations`,
    ticketList: `${ENV.evaluationV2}/evaluations`,
    ticketDetail: `${ENV.evaluationV2}/evaluations/:id`,
    transcript: `${ENV.evaluationV2}/transcripts/id`,
    attitude: `${ENV.evaluationV2}/:id/attitude-keyword-results`,
    audioInfo: `${ENV.evaluationV2}/audios/:id`,
    keyword: `${ENV.evaluationV2}/evaluations/:id/attitude-keyword-results`,
    estKpi: `${ENV.evaluationV2}/evaluations/:id/kpis`,
    history: `${ENV.evaluationV2}/evaluations/:id/update-histories`,
    statusQCKpi: `${ENV.evaluationV2}/kpis/results/status-by-qc`,
    kpiResult: `${ENV.evaluationV2}/kpis/results/:id`,
    comments: `${ENV.evaluationV2}/evaluations/:id/qc-recommends`,
    resetTicket: `${ENV.evaluationV2}/evaluations/:id/reset`,
    exportTicketDetails: `${ENV.evaluationV2}/evaluations/:id/export-evaluation`,
    downloadTicketInfo: `${ENV.evaluationV2}/evaluations/export-evaluations-by-list-evaluation-id`,
    revaluationJobs: `${ENV.evaluationV2}/revaluation-jobs`,
    ticketUnassigned: `${ENV.evaluationV2}/evaluations/auto-evaluated`,
    ticketAssigned: `${ENV.evaluationV2}/evaluations/assigned`,
    ticketAssignedDone: `${ENV.evaluationV2}/evaluations/done`,
    numOfEnableAssign: `${ENV.evaluationV2}/evaluations/num-of-enable-assign`,
    numOfAssigned: `${ENV.evaluationV2}/evaluations/num-of-assigned`,
    assignEvaluation: `${ENV.evaluationV2}/evaluations/assign-evaluations`,
    unassignEvaluation: `${ENV.evaluationV2}/evaluations/unassign-evaluations`
  }
};
