import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { SUCCESS_CODE } from '@shared/constant/respone-code.constant';
import { PermissionsService } from '@shared/service/permission.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AudioService } from '@admin/services/audio-manager/audio-manager.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FORM_CONTROL_STATUS } from '@shared/constant/form.constant';
import { differenceInCalendarDays } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { LayoutService } from '@core/layout/services/layout.service';
import { IMetadata } from '@admin/models/audio-manager/audio-metadata';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ReportService } from '@admin/services/report/report.service';
import { UploadManualResponse } from '@admin/models';

@Component({
  selector: 'app-audio-metadata',
  templateUrl: 'audio-metadata.component.html',
  styleUrls: ['audio-metadata.component.scss'],
  providers: [LayoutService]
})
export class AudioMetadataComponent implements OnInit {
  RIGHTS_CODE = RIGHTS_CODE;
  isPackageValid = true;
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private audioService: AudioService,
    private notification: NzNotificationService,
    private formBuilder: FormBuilder,
    public permissionsService: PermissionsService
  ) {}

  formGroup: FormGroup;
  items: FormArray;
  tempValue = {};

  fileNames = '';
  protected limitItems = 10;

  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  protected metadataData: IMetadata[] = [];
  private metadataConfig = {};

  getMetadataConfig(): void {
    this.spinner.show();
    this.audioService.getMetadataConfig().subscribe({
      next: (res: any) => {
        if (res.data.mapping) {
          this.metadataData = res.data.mapping;

          this.metadataData.forEach(meta => {
            this.metadataConfig[meta.key] = '';
          });
        }
        this.spinner.hide();
      },
      error: () => {
        this.notification.create(
          'error',
          this.translateService.instant('audio_manager.audio_metadata.notification.error'),
          this.translateService.instant('audio_manager.audio_metadata.notification.error_message')
        );
        this.spinner.hide();
      }
    });
  }

  ngOnInit() {
    this.initFormGroup();
    this.getMetadataConfig();
  }

  initFormGroup(): void {
    this.formGroup = new FormGroup({
      items: this.formBuilder.array([], [Validators.required])
    });
    this.items = this.formGroup.get('items') as FormArray;
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    if (this.items.controls.length >= this.limitItems) return false;
    this.addItem(file);
    this.getFileNames();
    return false;
  };

  private addItem(file: NzUploadFile): void {
    this.items.push(
      this.formBuilder.group({
        id: file.uid,
        fileName: [file.name, [Validators.pattern(RegExp(/^[^,]+$/))]],
        contactDate: [new Date(), [Validators.required]],
        agentId: ['', [Validators.required, Validators.pattern(RegExp(/^[^,]+$/))]],
        file,
        edit: true,
        submitEdit: false,
        metadata: this.formBuilder.group(this.metadataConfig)
      })
    );
  }

  protected isShowDialog = false;
  cancelUpload(): void {
    this.isShowDialog = true;
  }
  handleCancel(event: boolean): void {
    if (event) this.router.navigate(['/admin/audio-management']);
    this.isShowDialog = false;
  }

  enableEdit(i: number): void {
    this.items.controls[i].get('edit')?.setValue(true);
    this.items.controls[i].get('submitEdit')?.setValue(false);
  }
  disableEdit(i: number): void {
    this.items.controls[i].get('edit')?.setValue(false);
    this.items.controls[i].get('submitEdit')?.setValue(true);
  }

  setUpFormData(): { formData: FormData; filesValidate: FormData } {
    const formData: FormData = new FormData();
    const filesValidate: FormData = new FormData();
    const formFields = {
      fileNames: [] as string[],
      agentIds: [] as string[],
      contactDates: [] as string[],
      dataEvaluateKpis: [] as string[]
    };
    this.items.value.forEach(item => {
      const metadata: { [key: string]: string } = {};
      formFields.fileNames.push(item.fileName);
      formFields.agentIds.push(item.agentId);
      if (item.contactDate) formFields.contactDates.push(item.contactDate.getTime());
      for (const key in item.metadata) {
        const isDefaultData = this.metadataData.find(meta => meta.key === key)?.isDefaultData;

        if (!isDefaultData) {
          metadata[key] = item.metadata[key];
          continue;
        }

        if (!formFields[key + 's']) formFields[key + 's'] = [] as string[];
        formFields[key + 's'].push(item.metadata[key]);
      }
      formFields.dataEvaluateKpis.push(JSON.stringify(metadata));
    });
    for (const key in formFields) {
      if (key === 'dataEvaluateKpis') formData.append(key, formFields[key].join(';'));
      else formData.append(key, formFields[key].join(','));
    }
    this.items.value.forEach(e => {
      formData.append('files', e.file as File);
      filesValidate.append('files', e.file as File);
    });
    return { filesValidate, formData };
  }
  handleFormData({ filesValidate, formData }): void {
    this.spinner.show();
    this.audioService.validateUpload(filesValidate).subscribe({
      next: (res: any) => {
        if (res.message === SUCCESS_CODE.SUCCESS) {
          this.uploadData(formData);
        }
      },
      error: (err: any) => {
        this.spinner.hide();
        this.notification.create(
          'error',
          this.translateService.instant('audio_manager.upload_audio.title'),
          this.translateService.instant(err.errors.message)
        );
      }
    });
  }
  uploadData(formData: FormData): void {
    this.audioService.manualUploadAudio(formData).subscribe({
      next: (res: UploadManualResponse) => {
        this.spinner.hide();
        if (res.data.numOfFail === 0) {
          this.notification.create(
            'success',
            this.translateService.instant('audio_manager.upload_audio.title'),
            this.translateService.instant('audio_manager.upload_audio.upload_success')
          );
        } else {
          this.audioService.setAgreeUploadResultModal({
            isOpen: true,
            data: res.data
          });
        }
        this.router.navigate(['/admin/audio-management']);
      },
      error: () => {
        this.spinner.hide();
        this.notification.create(
          'error',
          this.translateService.instant('audio_manager.upload_audio.title'),
          this.translateService.instant('audio_manager.upload_audio.upload_fail')
        );
      }
    });
  }

  protected isSaveDisabled(): boolean {
    if (!this.items.controls.length) return true;

    return this.formGroup.status === FORM_CONTROL_STATUS.INVALID;
  }

  handleSave(): void {
    let isFormValid = !this.isSaveDisabled();
    if (!isFormValid) {
      this.notification.create(
        'error',
        this.translateService.instant('audio_manager.audio_metadata.notification.upload'),
        this.translateService.instant('audio_manager.audio_metadata.notification.upload_fail')
      );
      return;
    }

    this.items.controls.forEach(control => {
      control.get('submitEdit')?.setValue(true);
      if (control.status === FORM_CONTROL_STATUS.INVALID) {
        isFormValid = false;
        return;
      }
      control.get('edit')?.setValue(false);
    });

    if (!isFormValid) {
      this.notification.create(
        'error',
        this.translateService.instant('audio_manager.audio_metadata.notification.upload'),
        this.translateService.instant('audio_manager.audio_metadata.notification.upload_fail')
      );
      return;
    }

    const formData = this.setUpFormData();
    this.handleFormData(formData);
  }

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) > 0;

  deleteRow(i: number): void {
    this.items.removeAt(i);
    this.getFileNames();
  }
  getFileNames(): void {
    this.fileNames = '';
    this.items.value.forEach(e => {
      this.fileNames += `${e.fileName}, `;
    });
    this.fileNames = this.fileNames.slice(0, -2);
  }

  protected isModalVisible = false;
  protected audioToEdit: FormGroup;
  private oldAudioToEdit: FormGroup;
  showMetadataModal(i: number): void {
    this.isModalVisible = true;
    this.audioToEdit = this.items.controls[i] as FormGroup;
    this.oldAudioToEdit = this.items.controls[i].value;
  }
  changeModalVisible(isModalVisible: boolean): void {
    this.isModalVisible = isModalVisible;
    this.audioToEdit.reset(this.oldAudioToEdit);
  }

  handleSaveMetadata(isModalVisible: boolean): void {
    this.isModalVisible = isModalVisible;
    this.notification.create(
      'success',
      this.translateService.instant('audio_manager.audio_metadata.notification.success'),
      this.translateService.instant('audio_manager.audio_metadata.notification.success_message')
    );
  }
}
